@media (min-width: 768.1px) {
  .nav-links {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #003b59;
    align-items: center;
    color: #e8e8e8;
  }

  .navigation-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
  }

  .nav-links a {
    color: #e8e8e8;
    text-decoration: none;
    margin: 0 30px 0 0;
    font-size: 18px;
    transition: color 0.3s ease;
    transition: font-size 0.3s ease;
  }

  .nav-links a:last-child {
    margin-right: 2rem;
  }

  .nav-links a:hover {
    color: #fff;
    font-size: 20px;
  }

  .bm-burger-button {
    display: none;
  }

  .my-logo {
    display: flex;
    align-items: center;
    margin: 0 30px;
    font-weight: 300;
    font-size: 26px;
    cursor: pointer;
    color: #e8e8e8;
    text-decoration: none;
  }

  .nav-mobile {
    display: none;
  }

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-image: url('images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .homepage h1 {
    font-size: 3rem;
    color: #243741;
    font-weight: 300;
  }

  .homepage p {
    font-size: 1.4rem;
    color: #243741;
    margin: 10px 80px;
    border: 2px solid #fbfbfb;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgb(137, 137, 137);
    background-color: rgb(0, 59, 89, 0.3);
  }

  .text-and-image {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .image-and-text {
    display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
  }

  .image-first img {
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .image img {
    width: 80%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  h2 {
    text-decoration: underline;
    color: #003b59;
  }

  .text p,
  li {
    font-size: 1.2em;
    margin-inline: 10px;
  }

  .device img,
  video {
    width: 40%;
    border-radius: 10px;
    display: flex;
  }

  .about,
  .diseases,
  .device {
    padding-top: 50px;
  }

  .diseases {
    text-align: center;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgb(0, 59, 89, 0.2);
  }

  .additional-content img {
    width: 60%;
  }

  .content img {
    width: 70%;
    border-radius: 10px;
    align-self: center;
    transition: transform 0.3s ease-in-out;
  }

  .content img:hover {
    transform: scale(1.1);
  }

  .content video {
    width: 80%;
    border-radius: 10px;
    align-self: center;
    justify-content: center;
  }

  .media {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
  }

  .line {
    color: #003b59;
    margin: 100px;
  }

  .diseases h2 {
    text-decoration: underline;
    color: #003b59;
  }

  .content.no-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 10px 50px;
  }

  .device h2 {
    text-align: center;
  }

  .device-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .contact {
    padding: 80px 20px;
    background-color: #003b59;
    text-align: center;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-info {
    color: whitesmoke;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
  }

  .contact-info-header {
    border: 1px solid #003b59;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.8);
    background-color: rgb(0, 59, 89, 0.3);
  }

  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .contact-info p {
    font-size: 1.1rem;
  }

  .contact-form-container {
    max-width: 600px;
    margin: 10px auto;
    width: 100%;
  }

  .contact h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: whitesmoke;
    text-decoration: none;
  }

  .contact p {
    margin-bottom: 30px;
    font-size: 1.1rem;
    line-height: 1.6;
    color: whitesmoke;
  }

  .contact-form-cont {
    background-color: rgb(255, 255, 255);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.8);
  }

  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .form-element {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    color: #003b59;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    resize: vertical;
    min-height: 150px;
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #003b59;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    width: 100%;
    padding: 10px;
    background-color: #aed2ff;
    color: #003b59;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }

  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .success-message {
    margin-top: 20px;
    background-color: #003b59;
    color: whitesmoke;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
  }

  .success-message h2 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .success-message p {
    margin-bottom: 0;
  }

  .mobile {
    display: none;
  }

  /* .image-foot img {
    width: 500px;
  } */
}

@media (max-width: 768px) {
  .bm-burger-button {
    display: block;
    position: fixed;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 20px;
    z-index: 1001;
  }

  .mobile {
    background-color: #003b59;
    position: fixed;
    right: 0;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
  }

  .my-logo {
    display: none;
  }

  .links {
    display: none;
  }

  .bm-item {
    display: block;
    padding: 0.8em;
    text-decoration: none;
    color: #fffff0;
  }

  .bm-item:hover {
    color: #ccc;
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bm-item + .bm-item {
    margin-top: 20px;
  }

  img {
    width: 70%;
    border-radius: 10px;
    display: flex;
    margin: 10px auto;
  }

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120vh;
    text-align: center;
    background-image: url('images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about {
    padding-top: 35px;
  }

  .diseases {
    padding-top: 50px;
  }

  .device {
    padding-top: 50px;
  }

  .contact {
    padding: 80px 20px;
    background-color: #003b59;
    text-align: center;
  }

  .homepage h1 {
    font-size: 2rem;
    color: #243741;
    font-weight: 300;
    padding-top: 70px;
  }

  .homepage p {
    font-size: 1.4rem;
    color: #243741;
    margin: 10px 10px;
    border: 2px solid #fbfbfb;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgb(137, 137, 137);
    background-color: rgb(0, 59, 89, 0.3);
  }

  iframe {
    width: 100%;
  }

  .text-and-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .image-and-text {
    display: flex;
    flex-direction: column-reverse;
    margin: 20px;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
  }

  .image-first img {
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .image img {
    width: 80%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  h2 {
    text-decoration: underline;
    color: #003b59;
    margin-inline: 10px;
    font-size: 24px;
  }

  .text h3 {
    text-decoration: none;
    color: #003b59;
    margin: 20px;
    font-size: 18px;
  }

  .text p,
  li {
    font-size: 18px;
  }

  .text p {
    margin-inline: 20px;
  }

  .device img,
  video {
    width: 70%;
    border-radius: 10px;
    display: flex;
    margin: 10px auto;
  }

  .video1 {
    width: 80%;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-info {
    color: whitesmoke;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .contact-info-header {
    border: 1px solid #003b59;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.8);
    background-color: rgb(0, 59, 89, 0.3);
  }

  .contact-info h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .contact-info p {
    font-size: 1.1rem;
  }

  .contact-form-container {
    max-width: 600px;
    margin: 10px auto;
    width: 100%;
  }

  .contact h2 {
    margin-bottom: 20px;
    font-size: 1rem;
    color: whitesmoke;
    text-decoration: none;
  }

  .contact p {
    margin-bottom: 30px;
    font-size: 1.1rem;
    line-height: 1.6;
    color: whitesmoke;
  }

  .contact-form-cont {
    background-color: rgb(255, 255, 255);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.8);
  }

  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .form-element {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 6px;
    font-weight: 600;
    color: #003b59;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    resize: vertical;
    min-height: 150px;
  }

  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #003b59;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }

  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .submit-button:hover {
    width: 100%;
    padding: 10px;
    background-color: #aed2ff;
    color: #003b59;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }

  .success-message {
    margin-top: 20px;
    background-color: #003b59;
    color: whitesmoke;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
  }

  .success-message h2 {
    margin-top: 0;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .success-message p {
    margin-bottom: 0;
  }

  .additional-content img {
    width: 60%;
  }
}

@media screen and (max-width: 1000px) {
  .nav-links a {
    color: #e8e8e8;
    text-decoration: none;
    margin: 0 10px 0 0;
    font-size: 12px;
    transition: color 0.3s ease;
    transition: font-size 0.3s ease;
  }

  .nav-links a:last-child {
    margin-right: 4rem;
  }

  .nav-links a:hover {
    color: #fff;
    font-size: 20px;
  }
}

@media (min-width: 200px) and (max-width: 375px) {
  .homepage {
    /* padding-top: 160px; */
    height: 150vh;
  }

  .homepage h1 {
    font-size: 2rem;
    color: #243741;
    font-weight: 300;
     /* padding-top: 50px;  */
  }

  .homepage p {
    font-size: 1.3rem;
    color: #243741;
    margin: 10px 10px;
    border: 2px solid #fbfbfb;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 4px 8px rgb(137, 137, 137);
    background-color: rgb(0, 59, 89, 0.3);
  }
}

#go-up-btn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #003b59;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
}

#go-up-btn:hover {
  background-color: #ccd1ff;
}

.info {
  background-color: #003b59;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.info:hover {
  background-color: rgb(0, 59, 89, 0.3);
  color: #003b59;
  transform: scale(1.1);
}

.footer {
  background-color: #003b59;
  color: white;
  text-align: center;
  padding: 10px;
  left: 0;
  bottom: 0;
  border-top: 1px solid whitesmoke;
}
